import React from "react"
import Form from "./components/Form"

export default function index() {
  return (
    <section className="bg-darkere">
      <div className="col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1">
        <div className="feature boxed bg-secondary">
          <Form />
        </div>
      </div>
    </section>
  )
}
