import React from "react"
import { submitData } from "../../../../../../libs/helperFunctions"
import useFormFields from "../../../../../../libs/useFormFields"

export default function Form() {
  const [fields, handleFieldChange] = useFormFields({
    date: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    package: "",
    message: "",
    venue: "",
    referer: "",
  })

  const [loading, setLoading] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const [error, setError] = React.useState(false)

  const submitForm = async e => {
    e.preventDefault()
    setLoading(true)
    console.log("fields", fields)
    try {
      let data = await submitData(fields, "/.netlify/functions/book")
      data = data.json()
      console.log("data", data)
      setSuccess("The Ajax was a mistake. We are fixing it")
      setLoading(false)
    } catch (e) {
      console.error("error", e)
      setError("An error occured please try again")
      setLoading(false)
    }
  }

  return (
    <form
      className="text-center"
      name="Booking Form"
      // action=""
      onSubmit={submitForm}
    >
      <h4 className="uppercase mt48 mt-xs-0">Plan your Project</h4>
      <p className="lead mb64 mb-xs-24">
        Tell me about your project and how I can assist you with it.
      </p>

      <div className="overflow-hidden">
        <h6 className="uppercase">Your personal details</h6>
        <input
          type="text"
          name="firstName"
          className="col-md-6 validate-required"
          placeholder="First Name*"
          id="firstName"
          value={fields.firstName}
          onChange={handleFieldChange}
        />
        <input
          type="text"
          name="formName"
          className="col-md-6 hidden"
          placeholder="form Name"
          id="lastName"
          // value={fields.formName}
          onChange={handleFieldChange}
        />
        <input
          type="text"
          name="lastName"
          className="col-md-6 validate-required"
          placeholder="Last Name*"
          id="lastName"
          value={fields.lastName}
          onChange={handleFieldChange}
        />
        <input
          type="text"
          name="email"
          className="col-md-6 validate-required validate-email"
          placeholder="Your Email Address*"
          id="email"
          value={fields.email}
          onChange={handleFieldChange}
        />
        <input
          className="col-md-6"
          type="text"
          name="phone"
          placeholder="Your Phone Number"
          id="phone"
          value={fields.phone}
          onChange={handleFieldChange}
        />
        <textarea
          name="message"
          placeholder="Additional comments"
          rows="2"
          id="message"
          value={fields.message}
          onChange={handleFieldChange}
        ></textarea>
        <hr />
      </div>
      <div className="overflow-hidden">
        <div className="">
          <h6 className="uppercase">Package Details</h6>
          <div className="select-option">
            <i className="ti-angle-down"></i>
            <select name="shoot" id="package" onChange={handleFieldChange}>
              <option value="Default">Preferred Package</option>
              <option value="Senior Shoot">Senior Shoot</option>
              <option value="Portrait">Portrait</option>
              <option value="Couple">Couple</option>
              <option value="Engagement">Engagement</option>
              <option value="Weddings">Weddings</option>
              <option value="Events">Events or Concert</option>
            </select>
          </div>

          <div className="input-with-label text-left col-md-6">
            <span>Venue (if applicable):</span>
            <input
              type="text"
              name="venue"
              placeholder="Event Venue"
              id="venue"
              value={fields.venue}
              onChange={handleFieldChange}
            />
          </div>
          <div className="input-with-label text-left form-group col-md-6">
            <span>Preferred Date:</span>
            <input
              className="form-control"
              id="date"
              type="date"
              placeholder="Preferred Date"
              value={fields.date}
              onChange={handleFieldChange}
            />
          </div>
          <textarea
            name="referer"
            placeholder="How did you get to know about us "
            rows="1"
            id="referer"
            value={fields.referer}
            onChange={handleFieldChange}
          ></textarea>
          {success && (
            <div className="form-success">
              Thanks for your submission, we will be in touch shortly.
            </div>
          )}
          {error && <div className="form-error">{error}</div>}
          <button disabled={loading} type="submit">
            Submit
          </button>
        </div>
      </div>
    </form>
  )
}
