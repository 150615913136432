import React from "react"
import Layout from "../../../components/Layout"
import Hero from "../../../components/Hero"
import FormSection from "./components/FormSection"

export default function index() {
  return (
    <Layout>
      <Hero title="SCHEDULE A SHOOT" />
      <FormSection />
    </Layout>
  )
}
